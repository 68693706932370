.toolbar {
  width: 100%;
}

.variable-editor-title>select {
  margin: 0 12px;
}

.toolbar-button {
  max-width: unset !important;
}

.toolbar-menu-items {
  left: unset !important;
  right: 1px;
  overflow-y: scroll;
  max-height: 700px;
}

.toolbar-menu-items>li {
  max-width: unset !important;
}